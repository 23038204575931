.bg {
    height: 100vh;
    background-color: #162750;
    background-size: cover;
    /* padding: 1em; */
}

.player-wrapper {
    position: relative;
    padding-top: 56.25%;
    background-color: black;
    /* 720 / 1280 = 0.5625 */
}

.player-title {
    z-index: 15;
    font-size: 8px;
    position: absolute;
    right: 0;
    left: 0;
    margin-left: 30%;
    margin-right: 30%;
    bottom: 10px;
    border: 1px solid;
    padding: 3px;
    background-color: #000;
    color: aliceblue;
    align-items: center;
    text-align: center;
}

.player-wrapper-offline {
    position: relative;
    padding-top: 56.25%;
    background-color: black;
    /* 720 / 1280 = 0.5625 */
}

.react-player-offline {
    position: absolute;
    top: 40%;
    left: 40%;
    color: crimson;
    font-weight: bold;
}

.react-player {
    position: absolute;
    top: 0;
    left: 0;
    text-align: center;
}

.vertical-scrollable>.row {
    position: absolute;
    top: 120px;
    bottom: 100px;
    left: 180px;
    width: 50%;
    overflow-y: scroll;
}

.container-list {
    height: 50vh;
    /* background-color: #feed00; */
    /* padding: 1em; */
    /* margin-bottom: 2em; */
}